import React, { useEffect, useState } from 'react';
import { forEach, isEmpty, has, debounce } from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import {
  Tooltip,
  Typography,
  Box,
  Grid,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Zoom,
  TextField
} from '@material-ui/core';
import Table from '../../../../core-components/Table';
import SearchInput from '../../../../core-components/SearchInput';
import BreadCrumbs from '../../../../core-components/BreadCrumbs';
import AppConstants from '../../../../core-components/AppConstants';
import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone';
import Button from '../../../../core-components/Button';
import {
  CHARGE_TYPE,
  COMPANY_TYPE,
  ADDITIONAL_COST_REVIEW_STATUS,
  DELETE_REASON
} from '../../../../utils/Statuses';

import {
  getIDCheck,
  validateOperation,
  getErrorMessage,
  convertToIndianNumeration,
  errToastMessage
} from '../../../../utils/Utlities';
import { getAdminsCA, getCompaniesList, approveADC, rejectADC } from '../../../../api/admin';
import RejectModal from './RejectCostAndCloseCheck';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { capitalizeName } from '../../frontend-common/utils/Utilities';
function createData(
  dateSA,
  name,
  check,
  clientApprovingCA,
  delayStatus,
  amount,
  actionsApprovingSA
) {
  return { dateSA, name, check, clientApprovingCA, delayStatus, amount, actionsApprovingSA };
}

const useStyles = makeStyles({
  btnDiv: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: '13px'
  },
  infoBtn: {
    color: '#467eac',
    cursor: 'pointer',
    width: '6%',
    marginLeft: '2px'
  },
  infoBtn1: {
    width: '12%'
  },

  formControl: {
    minWidth: '120px',
    marginBottom: '8px',
    marginRight: '20px',
    marginLeft: '20px'
  },
  booleanField: {
    marginLeft: '22px',
    marginTop: '6px',
    color: '#57636D'
  },
  text: {
    marginTop: '16px',
    marginBottom: '16px',
    color: '#57636D'
  },
  clearFilter: {
    marginTop: '26px',
    marginLeft: '12px',
    cursor: 'pointer',
    color: '#8D84F8'
  },
  date: {
    marginTop: '18px'
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontSize: '13px',
    textAlign: 'left',
    marginLeft: '5px'
  },
  status: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '13px',
    justifyContent: 'center'
  },
  button: {
    borderRadius: 5,
    color: '#ffffff',
    borderColor: '#1565c0',
    fontSize: '13px',
    margin: '0 4px',
    textTransform: 'inherit'
  },
  clearBttn: {
    backgroundColor: '#1AAE9F',
    '&:hover': {
      backgroundColor: '#55B3B1'
    }
  },
  rejectBttn: {
    backgroundColor: '#4B5C6B',
    '&:hover': {
      backgroundColor: '#406882'
    }
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  filtertext: {
    color: '#5F6F7C'
  },
  disbaleClearBtn: {
    backgroundColor: '#BAE7E2',
    '&:hover': {
      backgroundColor: '#BAE7E2'
    }
  },
  userBtn: {
    borderRadius: 2,
    color: '#ffffff',
    borderColor: '#1565c0',
    fontSize: '8px',
    textTransform: 'inherit',
    backgroundColor: '#1AAE9F',
    '&:hover': {
      backgroundColor: '#55B3B1'
    }
  },
  deleteTooltip: {
    display: 'flex',
    flexDirection: 'column'
  },
  deleteReason: {
    fontSize: '14px'
  },
  inlineButton: {
    display: 'flex',
    justifyContent: 'center'
  },
  actionLabel: {
    display: 'flex',
    flexDirection: 'column'
  },
  rupeeSymbol: {
    fontFamily: 'Roboto'
  }
});

const headCells = [
  {
    id: 'dateSA',
    label: 'Date & SA',
    align: 'left'
  },
  {
    id: 'name',
    label: 'Candidate Name',
    align: 'left'
  },
  {
    id: 'check',
    label: 'Check',
    align: 'left'
  },

  {
    id: 'clientApprovingCA',
    label: 'Company Name & Approving CA',
    align: 'left'
  },

  {
    id: 'delayStatus',
    label: 'Approval Delay & Status',
    align: 'left'
  },
  {
    id: 'amount',
    label: 'Amount',
    align: 'left'
  },
  {
    id: 'actionsApprovingSA',
    label: 'Actions & Approving SA',
    align: 'left'
  }
];

const statusValues = [
  { label: 'All', value: 'ALL' },
  { label: 'Pending', value: 'PENDING' },
  { label: 'Approved & Auto-approved', value: 'APPROVED' },
  { label: 'Rejected & Auto-rejected', value: 'REJECTED' },
  { label: 'Deleted & Refunded', value: 'DELETED' }
];

export default function EnhancedTable(props) {
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const [companyId, setCompanyId] = useState('All');
  const [status, setStatus] = useState('PENDING');
  const [chargeType, setChargeType] = useState('ALL');
  const [companyType, setCompanyType] = useState('ALL');
  const [candidate, setCandidate] = useState();
  const [toDate, setDateTo] = useState();
  const [fromDate, setDateFrom] = useState();
  const [dateRange, setDateRange] = useState([null, null]);
  const [clientAdmin, setClientAdmin] = useState();
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [unApproved, setUnapproved] = useState(false);
  const [failedAutoApproved, setFailedAutoApproved] = useState(false);
  const [clientAdmins, setClientAdmins] = useState();
  const [companiesList, setCompaniesList] = useState([]);
  const [companySelected, setCompanySelected] = useState(null);
  const [companyName, setCompanyName] = useState('');
  const [rejectValue, setRejectValue] = useState();
  const [selectedADC, setSelectedADC] = useState();
  const breadCrumbsArray = [{ label: 'Home', href: `${AppConstants.baseURL}dashboard` }];
  const { invalidateReviewAdditionalChargesList, reviewAdditionalChargesList } = props;
  const COST_TYPE = [{ label: 'All', value: 'ALL' }, ...CHARGE_TYPE];
  const COMPANY_TYPES_WITH_ALL = [{ label: 'All', value: 'ALL' }, ...COMPANY_TYPE];
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    getReviewAdditionalChargesList({
      rowsPerPage,
      offset,
      status: 'PENDING'
    });
    return () => {
      invalidateReviewAdditionalChargesList();
    };
  }, []);

  const sortArray = (val1, val2) => {
    return val1.company_name.localeCompare(val2.company_name);
  };
  useEffect(() => {
    getCompaniesList().then((res) => {
      const sortedArray = [...res.data.data].sort(sortArray);
      setCompaniesList([{ company_id: 'All', company_name: 'All' }, ...sortedArray]);
    });
  }, []);

  useEffect(() => {
    if (has(props.reviewAdditionalChargesList, 'data')) {
      const rowsData =
        reviewAdditionalChargesList &&
        reviewAdditionalChargesList?.data &&
        reviewAdditionalChargesList?.data?.rows &&
        reviewAdditionalChargesList?.data?.rows?.length &&
        getRowData(props.reviewAdditionalChargesList.data.rows);
      setLoading(props.reviewAdditionalChargesList.loading);
      setRows(rowsData || []);
      setTotalCount(props.reviewAdditionalChargesList.data.count);
    }
  }, [props?.reviewAdditionalChargesList, clientAdmins]);

  const getRowData = (values) => {
    let companyRow = [];
    forEach(values, function (value, key) {
      if (!isEmpty(value)) {
        let rowData = createData(
          getDateAndSA(value),
          getName(value),
          getCheck(value),
          getClientAndAprovingCA(value),
          getDelayAndStatus(value),
          getAmount(value),
          getActions(value)
        );
        companyRow.push(rowData);
      }
    });
    return companyRow;
  };

  useEffect(() => {
    const rowsData =
      reviewAdditionalChargesList &&
      reviewAdditionalChargesList?.data &&
      reviewAdditionalChargesList?.data?.rows &&
      reviewAdditionalChargesList?.data?.rows?.length &&
      getRowData(props.reviewAdditionalChargesList.data.rows);
    setLoading(props.reviewAdditionalChargesList.loading);
    setRows(rowsData || []);
    setTotalCount(props?.reviewAdditionalChargesList?.data?.count);
  }, [clientAdmin]);

  const getDateAndSA = (value) => {
    const supervisor = value?.userAccessRight === 'SUPERVISOR' ? '[SM]' : '';
    const saID = value?.user?.id;
    return (
      <div className={classes.info}>
        <span>{new Date(value?.createdAt).toLocaleDateString('fr-CA')}</span>
        <span>{`${supervisor} ${capitalizeName(value?.user?.name)} (${saID})`}</span>
      </div>
    );
  };

  const getCandidate = (candidateId, companyId) => {
    const candidateUrl = `${AppConstants.baseURL}candidate/details?id=${candidateId}&company_id=${companyId}`;
    window.open(candidateUrl, '_blank');
  };
  const getName = (value) => {
    return (
      <div className={classes.info}>
        <span
          style={{ cursor: 'pointer', color: '#366CF3', fontWeight: 500 }}
          onClick={() => getCandidate(value?.candidateIdFk, value?.companyIdFk)}
        >
          {capitalizeName(value?.candidate?.name)}
        </span>
        <span>{`(SV${value?.candidate?.id})`}</span>
      </div>
    );
  };

  const getCheck = (value) => {
    let checkFor = '';
    switch (value?.check?.checkType) {
      case 'ID':
        checkFor = getIDCheck(value?.check?.checkdata?.type);
        break;
      case 'EDUCATION':
        checkFor =
          value?.educationChargedFor === 'UNIVERSITY'
            ? value?.check?.checkdata?.authority
            : value?.check?.checkdata?.instituteName;
        break;
      case 'EMPLOYMENT':
        checkFor = value?.check?.checkdata?.companyName;
        break;
      case 'ADDRESS':
        checkFor = value?.check?.checkdata?.type;
        break;
      case 'DRUG':
        checkFor = value?.check?.checkdata?.panelType;
        break;
      case 'WORLD':
        checkFor = '';
        break;
      case 'REFERENCE':
        checkFor = value?.check?.checkdata?.employerName;
        break;

      case 'CUSTOM':
        checkFor = value?.check?.checkdata?.checkName;
        break;
      case 'CIBIL':
        checkFor = '';
        break;
    }
    const id = value?.check?.checkdata?.id ? `(${value?.check?.checkdata?.id})` : '';
    return (
      <div className={classes.info}>
        <span>{`${
          value?.check?.checkType === 'CIBIL' ? 'Credit Check' : value?.check?.checkType
        } ${id}`}</span>
        <span>{checkFor}</span>
      </div>
    );
  };

  const getStatusLabel = (val) => {
    const tempStatus = ADDITIONAL_COST_REVIEW_STATUS.find((type) => type.value === val);
    return tempStatus.label;
  };

  const getAdminsCa = (value) => {
    setCompanySelected(value?.companyIdFk);
    setSelectedADC(value?.id);
    getAdminsCA(value?.companyIdFk)
      .then((response) => {
        if (response.data.data.length) {
          setClientAdmin(response.data.data[0].user_id);
          setClientAdmins(response.data.data);
        } else {
          errToastMessage('No admin list for this company');
        }
      })
      .catch((error) => {
        errToastMessage(error);
      });
  };
  const getClientAndAprovingCA = (value) => {
    let result;
    if (value.paymentsCompanyDeletedAdditionalCharge) {
      result = <span>N/A</span>;
    } else {
      switch (value?.status) {
        case 'AUTO_APPROVED_INSUFF_FUNDS':
          result = getStatusLabel(value.status);
          break;
        case 'APPROVED':
          result = (
            <span>{`${
              value.caApproveOrRejectedBy ? value.caApproveOrRejectedBy.name : 'Approved'
            } on ${new Date(value?.approvedOrRejectedOn).toLocaleDateString('fr-CA')}`}</span>
          );

          break;

        case 'APPROVAL_PENDING':
          result = (
            <div>
              {companySelected === value?.companyIdFk &&
              value?.id === selectedADC &&
              clientAdmins?.length ? (
                <select
                  name='clientAdmin'
                  id='main_dropdown'
                  value={clientAdmin}
                  onChange={(e) => {
                    setClientAdmin(e.target.value);
                  }}
                >
                  {clientAdmins &&
                    clientAdmins?.length &&
                    clientAdmins.map((item, index) => {
                      return (
                        <option key={index} value={item.user_id}>
                          {item.user_name}
                        </option>
                      );
                    })}
                </select>
              ) : (
                <Button
                  label={'Select User'}
                  className={`${classes.userBtn}`}
                  onClick={() => getAdminsCa(value)}
                />
              )}
            </div>
          );
          break;

        case 'REJECTED':
          result = (
            <span>{`${
              value.caApproveOrRejectedBy ? value.caApproveOrRejectedBy.name : 'Rejected'
            } on ${new Date(value?.approvedOrRejectedOn).toLocaleDateString('fr-CA')}`}</span>
          );
          break;

        case 'AUTO_APPROVED':
          result = `${getStatusLabel(value.status)}`;
          let delayDays;
          if (value?.fundsClearedOn) {
            delayDays = getDelay(value);
            result =
              result +
              ` on  ${new Date(value.fundsClearedOn).toLocaleDateString('fr-CA')} ${
                delayDays > 0 ? '(Delayed)' : ''
              }`;
          }
          break;
        default:
          break;
      }
    }

    return (
      <div className={classes.info}>
        <span>{value?.company?.name}</span>
        {result}
      </div>
    );
  };

  const getDelay = (value) => {
    if (value?.paymentsCompanyDeletedAdditionalCharge) {
      return '-';
    } else if (value?.status === 'AUTO_APPROVED') {
      if (value?.fundsClearedOn) {
        return moment(moment(value.fundsClearedOn, 'YYYY-MM-DD')).diff(
          moment(value?.createdAt, 'YYYY-MM-DD'),
          'days'
        );
      } else {
        return '0';
      }
    } else if (
      value?.status === 'APPROVAL_PENDING' ||
      value?.status === 'AUTO_APPROVED_INSUFF_FUNDS'
    ) {
      const currentDate = moment().format('YYYY-MM-DD');
      return moment(moment(currentDate)).diff(moment(value?.createdAt, 'YYYY-MM-DD'), 'days');
    } else {
      if (value?.approvedOrRejectedOn) {
        return moment(moment(value?.approvedOrRejectedOn, 'YYYY-MM-DD')).diff(
          moment(value?.createdAt, 'YYYY-MM-DD'),
          'days'
        );
      } else {
        return '-';
      }
    }
  };
  const getDelayAndStatus = (value) => {
    let status, finalStatus;
    if (value?.paymentsCompanyDeletedAdditionalCharge) {
      status = ADDITIONAL_COST_REVIEW_STATUS.find((type) => type.value === 'DELETED');
      finalStatus = <span style={{ color: `${status.color}` }}>{status.icon}</span>;
    } else {
      status = ADDITIONAL_COST_REVIEW_STATUS.find((type) => type.value === value?.status);
      finalStatus = <span style={{ color: `${status.color}` }}>{status.icon}</span>;
    }
    const delay = getDelay(value);
    if (value?.status === 'AUTO_APPROVED') {
      `${finalStatus} on ${new Date(value?.fundsClearedOn).toLocaleDateString('fr-CA')}  ${
        delay > 0 ? '(Delayed)' : ''
      }`;
    }
    return (
      <div className={classes.status}>
        <span style={{ marginRight: '26px' }}>{delay}</span>
        <Tooltip TransitionComponent={Zoom} title={status.label} arrow={true}>
          {finalStatus}
        </Tooltip>
      </div>
    );
  };

  const getAmount = (value) => {
    const amount = convertToIndianNumeration(value?.amount);
    return (
      <div className={classes.btnDiv}>
        <div>
          <span>{CHARGE_TYPE?.find((val) => val?.value === value?.chargeType)?.label}</span>
          {value?.chargeType === 'OTHER' && (
            <Tooltip TransitionComponent={Zoom} title={value?.publicNote} arrow={true}>
              <InfoTwoToneIcon className={`${classes.infoBtn} ${classes.infoBtn1}`} />
            </Tooltip>
          )}
        </div>
        <span style={{ fontFamily: 'Roboto' }}>₹{amount + '.00'}</span>
      </div>
    );
  };

  const createPayload = (value) => {
    const caName = clientAdmins.filter((admin) => admin.user_id === Number(clientAdmin))[0]
      .user_name;
    return {
      companyId: value?.company?.id,
      additionalChargeMapping: [
        {
          userId: clientAdmin,
          id: value?.id,
          caName
        }
      ]
    };
  };
  const clearAdchandler = (value) => {
    if (clientAdmin) {
      const payload = createPayload(value);

      approveADC(payload)
        .then((res) => {
          toast.success('Additional Cost Cleared Successfully');
          setClientAdmin('');
          setCompanySelected('');
          setSelectedADC('');
          getReviewAdditionalChargesList({
            rowsPerPage,
            offset,
            toDate,
            fromDate,
            companyId,
            status,
            chargeType,
            candidate,
            failedAutoApproved,
            unApproved
          });
        })
        .catch((err) => console.log(err));
    } else {
      errToastMessage('Please select Company Admin');
    }
  };
  const rejectAdchandler = () => {
    if (clientAdmin) {
      const payload = createPayload(rejectValue);
      rejectADC(payload)
        .then((res) => {
          setShowRejectModal(false);
          setRejectValue('');
          setClientAdmin('');
          setCompanySelected('');
          setSelectedADC('');
          toast.success('Additional Cost Rejected Successfully');
          getReviewAdditionalChargesList({
            rowsPerPage,
            offset,
            toDate,
            fromDate,
            companyId,
            status,
            chargeType,
            candidate,
            failedAutoApproved,
            unApproved
          });
        })
        .catch((err) => console.log(err));
    } else {
      errToastMessage('Please select Company Admin');
    }
  };

  const getLabel = (value) => {
    let labelValue = '';
    if (value?.status === 'APPROVAL_PENDING') {
      labelValue = 'Deleted:';
    } else if (value?.status === 'APPROVED' && value?.approvedOrRejectedOn) {
      labelValue = 'Deleted and Refunded by Ops:';
    } else if (value?.status === 'AUTO_APPROVED' && value?.fundsClearedOn) {
      labelValue = 'Deleted and Refunded by Ops:';
    } else {
      labelValue = 'Deleted:';
    }
    return labelValue;
  };
  const getActions = (value) => {
    let actions = null;
    const disable = value?.isInsuffFund === 1 ? true : false;
    const classBtn = disable ? classes.disbaleClearBtn : classes.clearBttn;
    if (value?.paymentsCompanyDeletedAdditionalCharge) {
      const supervisor =
        value.paymentsCompanyDeletedAdditionalCharge.deletedByUserAccessRight === 'SUPERVISOR'
          ? value.paymentsCompanyDeletedAdditionalCharge.isAutoDeleted
            ? '[CA]'
            : '[SM]'
          : '';
      const label = getLabel(value);
      const adcDeleteReason = value?.paymentsCompanyDeletedAdditionalCharge?.deletionReason;
      actions = (
        <div className={classes.container}>
          <div className={classes.actionLabel}>
            <span>{label}</span>
            <span>
              {`
                  ${supervisor}
                  ${value?.paymentsCompanyDeletedAdditionalCharge?.deletedByUser?.name}
                      on ${new Date(
                        value.paymentsCompanyDeletedAdditionalCharge?.createdAt
                      ).toLocaleDateString('fr-CA')}`}
            </span>
          </div>
          <Tooltip
            TransitionComponent={Zoom}
            title={
              <div className={classes.deleteTooltip}>
                <span className={classes.deleteReason}>
                  <b>
                    {' '}
                    {value.paymentsCompanyDeletedAdditionalCharge.isAutoDeleted
                      ? 'Auto-deleted as check was closed by CA'
                      : DELETE_REASON.find((reason) => reason.value === adcDeleteReason)?.label ||
                        '-'}
                  </b>
                </span>
                <span> {value.paymentsCompanyDeletedAdditionalCharge.deletionComment}</span>
              </div>
            }
            arrow={true}
          >
            <InfoTwoToneIcon className={classes.infoBtn} />
          </Tooltip>
        </div>
      );
    } else {
      switch (value?.status) {
        case 'APPROVAL_PENDING':
          actions = (
            <div className={classes.inlineButton}>
              {/* Todo APi call on buttons */}
              <Button
                label={'Clear'}
                className={`${classes.button}  ${classBtn}`}
                disabled={disable}
                onClick={() => clearAdchandler(value)}
              />
              <Button
                label={'Reject'}
                className={`${classes.button} ${classes.rejectBttn}`}
                onClick={() => {
                  setRejectValue(value);
                  setShowRejectModal(!showRejectModal);
                }}
              />
            </div>
          );
          break;
        case 'REJECTED':
          if (value?.approvedOrRejectedBy) {
            actions = (
              <div className={classes.info}>
                <span>Rejected by Ops:</span>
                <span>
                  {`
            ${value?.approvedOrRejectedBy?.name || ''}
             on ${new Date(value?.approvedOrRejectedOn).toLocaleDateString('fr-CA')}`}
                </span>
              </div>
            );
          }
          break;
        case 'APPROVED':
          if (value?.approvedOrRejectedBy) {
            actions = (
              <div className={classes.info}>
                <span>Approved by Ops:</span>
                <span>
                  {`
            ${value?.approvedOrRejectedBy?.name || ''}
             on ${new Date(value?.approvedOrRejectedOn).toLocaleDateString('fr-CA')}`}
                </span>
              </div>
            );
          }
          break;
        default:
          break;
      }
    }

    return actions;
  };

  // call API to fetch data
  const getReviewAdditionalChargesList = ({
    rowsPerPage,
    offset,
    toDate,
    fromDate,
    companyId,
    status,
    chargeType,
    companyType,
    candidate,
    showFailedAutoApproved,
    showUnapproved
  }) => {
    setLoading(true);
    let query = {
      limit: rowsPerPage,
      offset
    };
    if (toDate) {
      query = {
        ...query,
        toDate
      };
    }
    if (fromDate) {
      query = {
        ...query,
        fromDate
      };
    }
    if (companyId && companyId !== 'All') {
      query = {
        ...query,
        companyId
      };
    }
    if (status && status !== 'ALL') {
      query = {
        ...query,
        status
      };
    }
    if (chargeType && chargeType !== 'ALL') {
      query = {
        ...query,
        chargeType
      };
    }
    if (companyType && companyType !== 'ALL') {
      query = {
        ...query,
        companyType
      };
    }
    if (candidate) {
      query = {
        ...query,
        candidate
      };
    }
    if (showFailedAutoApproved) {
      query = {
        ...query,
        showFailedAutoApproved
      };
    }
    if (showUnapproved) {
      query = {
        ...query,
        showUnapproved
      };
    }
    props
      ?.getReviewAdditionalChargesList(query)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  //these are filters
  const setDates = (dates) => {
    setDateRange(dates);
    if (dates[0] && dates[1]) {
      const date1 = new Date(dates[0]).toLocaleDateString('fr-CA');
      const date2 = new Date(dates[1]).toLocaleDateString('fr-CA');
      setDateFrom(date1);
      setDateTo(date2);
      applyDateFilter(date1, date2);
    } else if (!dates[0] && !dates[1]) {
      setDateFrom('');
      setDateTo('');
      applyDateFilter('', '');
    }
  };
  const getDateFilters = () => {
    return (
      <div>
        <span className={classes.filtertext}>Filter by date</span>
        <DatePicker
          selectsRange={true}
          startDate={dateRange[0]}
          endDate={dateRange[1]}
          maxDate={new Date()}
          onChange={(update) => {
            setDates(update);
          }}
          isClearable={true}
        />
      </div>
    );
  };

  const getCompanyFilter = () => {
    return (
      <Autocomplete
        id='select-company'
        sx={{ width: 300 }}
        options={companiesList}
        autoHighlight
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.company_name)}
        getOptionSelected={(option, value) => {
          if (option.company_name === value) {
            return option.company_name;
          } else {
            return value;
          }
        }}
        renderOption={(option) => (
          <Grid container alignItems='center'>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant='body2' color='initial'>
                {option.company_name}
              </Typography>
            </Grid>
          </Grid>
        )}
        onChange={(event, newValue) => {
          setCompanyName(newValue?.company_name || '');
          applyCompanyFilter(newValue?.company_id || '');
        }}
        value={companyName}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Select Company'
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-company'
            }}
          />
        )}
      />
    );
  };

  const getStatusFilter = () => {
    return (
      <FormControl variant='outlined' className={classes.formControl} size='small'>
        <span className={classes.filtertext}>Approval Status</span>
        <Select
          labelId='status'
          id='status'
          name='status'
          value={status}
          onChange={(e) => {
            handleStatusDropdownSelect(e.target.value);
          }}
          fullWidth={true}
        >
          {statusValues.map((item, index) => {
            return (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const getChargeTypeFilter = () => {
    return (
      <FormControl variant='outlined' className={classes.formControl} size='small'>
        <span className={classes.filtertext}>Cost Type</span>
        <Select
          labelId='CostType'
          id='CostType'
          name='CostType'
          value={chargeType}
          onChange={(e) => {
            applyChargeTypeFilter(e.target.value);
          }}
          fullWidth={true}
        >
          {COST_TYPE.map((item, index) => {
            return (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const getCompanyTypeFilter = () => {
    return (
      <FormControl variant='outlined' className={classes.formControl} size='small'>
        <span className={classes.filtertext}>Company Type</span>
        <Select
          labelId='CompanyType'
          id='CompanyType'
          name='CompanyType'
          value={companyType}
          onChange={(e) => {
            handleCompanyTypeChange(e.target.value);
          }}
          fullWidth={true}
        >
          {COMPANY_TYPES_WITH_ALL.map((item, index) => {
            return (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const getCandidateFilter = () => {
    return (
      <>
        <span className={classes.filtertext}>Search Candidate</span>
        <SearchInput
          placeholder={'Search by Name or SVID'}
          handleSearch={debounce((candidateName) => {
            applyCandidateFilter(candidateName?.trim());
          }, 500)}
        />
      </>
    );
  };

  // these are for calling filters API

  const applyDateFilter = (date1, date2) => {
    getReviewAdditionalChargesList({
      rowsPerPage,
      offset: 0,
      toDate: date2,
      fromDate: date1,
      companyId,
      status,
      chargeType,
      companyType,
      candidate,
      showFailedAutoApproved: failedAutoApproved,
      showUnapproved: unApproved
    });
  };
  const applyCompanyFilter = (id) => {
    setCompanyId(id);
    setOffset(0);
    getReviewAdditionalChargesList({
      rowsPerPage,
      offset: 0,
      toDate,
      fromDate,
      companyId: id !== 'All' ? id : undefined,
      status,
      chargeType,
      companyType,
      candidate,
      showFailedAutoApproved: failedAutoApproved,
      showUnapproved: unApproved
    });
  };

  const handleStatusDropdownSelect = (statusValue) => {
    setOffset(0);
    setLoading(true);
    setStatus(statusValue);
    getReviewAdditionalChargesList({
      rowsPerPage,
      offset: 0,
      toDate,
      fromDate,
      companyId,
      status: statusValue,
      chargeType,
      companyType,
      candidate,
      showFailedAutoApproved: failedAutoApproved,
      showUnapproved: unApproved
    });
  };

  const applyChargeTypeFilter = (charge) => {
    setChargeType(charge);
    setOffset(0);
    getReviewAdditionalChargesList({
      rowsPerPage,
      offset: 0,
      toDate,
      fromDate,
      companyId,
      status,
      chargeType: charge,
      companyType,
      candidate,
      showFailedAutoApproved: failedAutoApproved,
      showUnapproved: unApproved
    });
  };

  const handleCompanyTypeChange = (type) => {
    setCompanyType(type);
    setOffset(0);
    getReviewAdditionalChargesList({
      rowsPerPage,
      offset: 0,
      toDate,
      fromDate,
      companyId,
      status,
      chargeType,
      companyType: type,
      candidate,
      showFailedAutoApproved: failedAutoApproved,
      showUnapproved: unApproved
    });
  };

  const applyCandidateFilter = (name) => {
    setCandidate(name);
    setOffset(0);
    getReviewAdditionalChargesList({
      rowsPerPage,
      offset: 0,
      toDate,
      fromDate,
      companyId,
      status,
      chargeType,
      companyType,
      candidate: name,
      showFailedAutoApproved: failedAutoApproved,
      showUnapproved: unApproved
    });
  };

  const failedApprovedCostFilter = (apprv) => {
    setFailedAutoApproved(apprv);
    setOffset(0);
    getReviewAdditionalChargesList({
      rowsPerPage,
      offset: 0,
      toDate,
      fromDate,
      companyId,
      status,
      chargeType,
      companyType,
      candidate,
      showFailedAutoApproved: apprv,
      showUnapproved: unApproved
    });
  };
  const unApprovedCostFilter = (apprvCost) => {
    setUnapproved(apprvCost);
    setOffset(0);
    getReviewAdditionalChargesList({
      rowsPerPage,
      offset: 0,
      toDate,
      fromDate,
      companyId,
      status,
      chargeType,
      companyType,
      candidate,
      showFailedAutoApproved: failedAutoApproved,
      showUnapproved: apprvCost
    });
  };
  const handleChangePage = (newPage) => {
    setOffset(newPage);
    setLoading(true);
    getReviewAdditionalChargesList({
      rowsPerPage,
      offset: newPage,
      toDate,
      fromDate,
      companyId,
      status,
      chargeType,
      companyType,
      candidate,
      showFailedAutoApproved: failedAutoApproved,
      showUnapproved: unApproved
    });
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(parseInt(value, 10));
    setOffset(0);
    setLoading(true);
    getReviewAdditionalChargesList({
      rowsPerPage: parseInt(value, 10),
      offset: 0,
      toDate,
      fromDate,
      companyId,
      status,
      chargeType,
      companyType,
      candidate,
      showFailedAutoApproved: failedAutoApproved,
      showUnapproved: unApproved
    });
  };

  const getFailedApprovedCost = () => {
    return (
      <Box className={classes.booleanField}>
        <FormControlLabel
          checked={failedAutoApproved}
          control={<Checkbox color='primary' value={failedAutoApproved} />}
          label={'Show failed auto-approved costs'}
          onChange={(e) => {
            const apprv = failedAutoApproved;
            failedApprovedCostFilter(!apprv);
          }}
        />
      </Box>
    );
  };

  const getUnApprovedCost = () => {
    return (
      <Box className={classes.booleanField}>
        <FormControlLabel
          checked={unApproved}
          control={<Checkbox color='primary' value={unApproved} />}
          label={'Show unapproved costs with Insufficient Funds'}
          onChange={(e) => {
            const apprv = unApproved;
            unApprovedCostFilter(!apprv);
          }}
        />
      </Box>
    );
  };

  const getClearFilter = () => {
    return (
      <div className={classes.clearFilter}>
        <span onClick={clearAllFilter}>Clear All</span>
      </div>
    );
  };

  const clearAllFilter = () => {
    setOffset(0);
    setLoading(true);
    setStatus('PENDING');
    setDateRange([null, null]);
    setDateTo('');
    setDateFrom('');
    setChargeType('ALL');
    setCompanyType('ALL');
    setCompanyId('All');
    setCandidate('');
    setUnapproved(false);
    setFailedAutoApproved(false);
    setCompanyName('');
    getReviewAdditionalChargesList({ rowsPerPage, offset: 0, status: 'PENDING' });
  };

  const handleClose = () => {
    setShowRejectModal(!showRejectModal);
  };

  return (
    <>
      <BreadCrumbs linksArray={breadCrumbsArray} current={'Additional Cost Review'} />
      <Typography variant='h6' component='h3' className={classes.text}>
        Additional Cost Reviews
      </Typography>

      <Box mt={2}>
        <Grid container direction='row'>
          <Grid item xl={2} lg={3} md={4} sm={5} xs={12}>
            {getDateFilters()}
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={5} xs={12}>
            {getCompanyFilter()}
          </Grid>
          <Grid item>{getStatusFilter()}</Grid>
          <Grid item>{getChargeTypeFilter()}</Grid>
          <Grid item>{getCompanyTypeFilter()}</Grid>
          <Grid item>{getCandidateFilter()}</Grid>

          <Grid item>{getClearFilter()}</Grid>
        </Grid>
        <Grid container direction='row'>
          <Grid item className={classes.text}>
            {`Pending(${props?.reviewAdditionalChargesList?.data?.pending})`}
          </Grid>
          <Grid item>{getFailedApprovedCost()}</Grid>
          <Grid item>{getUnApprovedCost()}</Grid>
        </Grid>
      </Box>
      <Table
        headCells={headCells}
        rowData={rows}
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={offset}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        loading={loading || reviewAdditionalChargesList?.loading}
      />
      {showRejectModal ? (
        <RejectModal
          onClose={handleClose}
          rejectHandler={rejectAdchandler}
          rejectValue={rejectValue}
        />
      ) : null}
    </>
  );
}
